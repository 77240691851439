import React from 'react';
import './App.css';
import Form from './Components/Form/Form'; // Подключаем форму

function App() {
  return (
    <div className="App">
      <Form /> {/* Оставляем только компонент формы */}
    </div>
  );
}

export default App;
