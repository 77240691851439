import React, { useState, useEffect } from 'react';
import './Form.css';
import { ethers } from 'ethers'; // Для работы с Ethereum
import Web3Modal from 'web3modal'; // Для подключения кошельков
import { config } from './Config';

const Form = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('ETH');
  const [tokenAmount, setTokenAmount] = useState(0);
  const [raisedAmount, setRaisedAmount] = useState(0); // Собранные средства из контракта
  const [status, setStatus] = useState('');
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  const tokenPrice = config.tokenPrice;

  // Таймер для пресейла
  useEffect(() => {
    const countdownDate = new Date(config.countdown).getTime();

    const updateTimer = () => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(timerInterval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  // Подключение кошелька через Web3Modal
  const connectWallet = async () => {
    try {
      const web3Modal = new Web3Modal();
      const instance = await web3Modal.connect();
      const provider = new ethers.BrowserProvider(instance);
      const signer = provider.getSigner();

      const address = await signer.getAddress();
      setWalletAddress(address);
      setStatus('Wallet connected');

      // Сохраним адрес кошелька в localStorage, чтобы не спрашивать при обновлении
      localStorage.setItem('walletAddress', address);
    } catch (error) {
      setStatus('Error connecting to wallet');
    }
  };

  // Проверка, подключен ли кошелек ранее
  useEffect(() => {
    const savedAddress = localStorage.getItem('walletAddress');
    if (savedAddress) {
      setWalletAddress(savedAddress);
    }
  }, []);

  // Обновление суммы токенов при вводе суммы
  useEffect(() => {
    setTokenAmount(amount / tokenPrice);
  }, [amount, tokenPrice]);

  // Получение текущей суммы собранных средств из контракта
  const getRaisedAmount = async () => {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const tokenAddress = config.tokenAddress;
    const tokenContract = new ethers.Contract(
      tokenAddress,
      [
        // Интерфейс контракта для получения баланса или проданных токенов
        "function balanceOf(address owner) view returns (uint256)"
      ],
      provider
    );

    try {
      // Получаем баланс контракта или другого адреса
      const balance = await tokenContract.balanceOf(config.recipientAddress); 
      setRaisedAmount(ethers.formatUnits(balance, 6)); // Форматируем баланс (6 знаков для USDT)
    } catch (error) {
      setStatus('Error fetching raised amount');
    }
  };

  // Вызов функции при загрузке компонента
  useEffect(() => {
    getRaisedAmount();
  }, []);

  // Отправка транзакции на покупку токенов ERC-20
  const handlePurchase = async () => {
    if (!walletAddress) {
      setStatus('Please connect your wallet');
      return;
    }

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();

    const tokenAddress = config.tokenAddress; // Адрес ERC-20 контракта
    const tokenContract = new ethers.Contract(
      tokenAddress,
      [
        "function transfer(address to, uint256 amount) public returns (bool)"
      ],
      signer
    );

    try {
      const tx = await tokenContract.transfer(config.recipientAddress, ethers.parseUnits(amount, 6)); // 6 знаков для USDT
      setStatus(`Transaction sent! Tx hash: ${tx.hash}`);
    } catch (error) {
      setStatus(`Transaction failed: ${error.message}`);
    }
  };

  return (
    <div className="presale-form">
      <div className="timer-section">
        <h2>Next Price Increase In</h2>
        <div className="timer">
          <div>{timeLeft.days} Days</div>
          <div>{timeLeft.hours} Hours</div>
          <div>{timeLeft.minutes} Minutes</div>
          <div>{timeLeft.seconds} Seconds</div>
        </div>
      </div>

      <div className="raised-section">
        <p>USDT RAISED: ${raisedAmount} / ${config.targetAmount}</p>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${(raisedAmount / config.targetAmount) * 100}%` }}></div>
        </div>
      </div>

      <div className="purchase-info">
        <p>Your purchased {config.tokenSymbol} = 0</p>
        <p>Your stakeable {config.tokenSymbol} = 0</p>
        <p>1 {config.tokenSymbol} = ${tokenPrice.toFixed(5)}</p>
      </div>

      <div className="currency-selector">
        <button className={currency === 'ETH' ? 'active' : ''} onClick={() => setCurrency('ETH')}>ETH</button>
        <button className={currency === 'USDT' ? 'active' : ''} onClick={() => setCurrency('USDT')}>USDT</button>
      </div>

      <div className="amount-input">
        <label>Pay with {currency}</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
        />
        <div className="receive-token">
          <p>Receive {config.tokenSymbol}</p>
          <input type="number" value={tokenAmount.toFixed(2)} readOnly />
        </div>
      </div>

      <button className="connect-wallet-btn" onClick={connectWallet}>
        {walletAddress ? `Connected: ${walletAddress.substring(0, 6)}...${walletAddress.substring(38)}` : 'Connect Wallet'}
      </button>

      {walletAddress && (
        <button className="purchase-btn" onClick={handlePurchase}>
          Purchase Tokens
        </button>
      )}

      <p className="status-message">{status}</p>

      <p className="no-wallet">
        Don’t have a wallet? <a href={config.walletLink}>Get one here!</a>
      </p>
    </div>
  );
};

export default Form;
