// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Высота окна браузера, чтобы форма была по центру */
  margin: 0;
  padding: 0;
  background-color: #f7f7f7; /* Цвет фона для всей страницы */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,qDAAqD;EACpE,SAAS;EACT,UAAU;EACV,yBAAyB,EAAE,gCAAgC;AAC7D","sourcesContent":[".App {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Высота окна браузера, чтобы форма была по центру */\n  margin: 0;\n  padding: 0;\n  background-color: #f7f7f7; /* Цвет фона для всей страницы */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
